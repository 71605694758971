/**
 * Holograph Subscription Class - provides functionality to allow users to opt in to receive
 * marketing information from our systems.
 */

import { IValidateConfigs, IHoloNamespace, ajaxRequest } from '@holograph/holo-namespace-site-fa';
import { ISubscriptionConfig } from '../interfaces/config';
import { ISubscriptionRequest } from '../interfaces/request';
import { ISubscriptionResponse } from '../interfaces/response';

export class Subscription {
    /**
     * Holograph namespace
     */
    private _holo: IHoloNamespace;
    /**
     * subscription type eg. NewsletterOptIn (relates to the subscription_type table)
     */
    private _subscriptionType: string;
    /**
     * DOM element which wraps the subscription block markup
     */
    private _wrapper: HTMLDivElement;
    /**
     * DOM element for the button
     */
    private _button: HTMLButtonElement;
    /**
     * DOM element for the email input
     */
    private _emailInput: HTMLInputElement;
    /**
     * DOM element which store an action for the widget
     */
    private _action = 'subscribe';
    /**
     * Input name of subscription
     */
    private _emailInputName: string;
    /**
     * creates an instance of our subscription class
     * @param holo holograph namespace
     * @param config configuration structure
     */
    constructor(holo: IHoloNamespace, config: ISubscriptionConfig) {
        // store a pointer to the holograph namespace
        this._holo = holo;
        // store the subscription type
        this._subscriptionType = config.subscriptionType;
        // grab a pointer to the button
        this._button = config.wrapper.querySelector<HTMLButtonElement>('button');
        // grab a pointer to the email address input
        this._emailInput = config.wrapper.querySelector<HTMLInputElement>('[data-email-address]');
        // Check if we have an override for the action
        this._action = config.wrapper.getAttribute('data-action') || this._action;
        // create a unique input name for validation on submit
        this._emailInputName = 'holo-subscription-email-input-' + String(config.index);
        // grab a pointer to the wrapper element
        this._wrapper = config.wrapper;
    }

    /**
     * initialises our object by binding required events to elements within the DOM
     */
    render(): void {
        // register an event to trigger subscription on click
        this._button.addEventListener('click', () => this._subscribe());
        // register an event to trigger subscription on enter keypress
        this._emailInput.addEventListener('keypress', (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                this._subscribe();
            }
        });
        // setup the name attribute on the input element
        this._emailInput.setAttribute('name', this._emailInputName);
    }

    /**
     * Subscribes the users email address to the subscription list denoted by the
     * subscriptionType property
     */
    private _subscribe(): void {
        // setup the validation config
        const validateConfig: IValidateConfigs = {};
        validateConfig[this._emailInputName] = 'email';

        // validate_single_field - defined in validate.js
        if (this._holo.validate.verifyField(this._emailInput, validateConfig)) {
            const params: ISubscriptionRequest = {
                ac: this._action,
                subscription_type: this._subscriptionType,
                email: this._emailInput.value,
                widget: 'widget-subscription.pl',
                valid_json: 1,
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ajaxRequest<ISubscriptionResponse>(this._holo.scripts.infxsystem.widget, params as any, 'POST')
                .then((response) => {
                    // add an additional class so that elements within the subscription wrapper
                    // can be altered as required
                    this._wrapper.classList.add('subscribed');
                    // callback with response from backend
                    if (typeof response.message !== 'undefined') {
                        this._holo.validate.displayMessage({
                            $form: $(this._wrapper),
                            message: { body: response.message, state: response.message_state },
                        });
                    }
                    if (response?.already_subscribed) {
                        window.dispatchEvent(new CustomEvent('subscription-already-subscribed'));
                    } else {
                        window.dispatchEvent(new CustomEvent('subscription-success'));
                    }
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                });
        } else {
            this._holo.validate.displayMessage({
                $form: $(this._wrapper),
                message: { body: "Please ensure you've entered a valid email address.", state: 'error-state' },
            });
        }
    }
}
