import { resolveHolo } from '@holograph/holo-namespace-site-fa';
import { Subscription } from './classes/Subscription';

// simple init method
const init = async (): Promise<void> => {
    const holo = await resolveHolo();
    // check to see if we have any subscription inputs within the DOM structure, these
    // are currently driven by the subscription widget.
    const subscriptionInputWrapperElements = Array.from(document.querySelectorAll<HTMLDivElement>('.subscription-input-wrapper'));
    subscriptionInputWrapperElements.forEach((element: HTMLDivElement, index: number) => {
        if (element.dataset) {
            if (!element.dataset.rendered) {
                new Subscription(holo, {
                    index,
                    wrapper: element,
                    subscriptionType: element.dataset.subscriptionType,
                }).render();
                element.dataset.rendered = '1';
            } // else already rendered
        } else {
            // eslint-disable-next-line no-console
            console.error('unable to locate subscription type property');
        }
    });
};

// trigger init on load
document.addEventListener('DOMContentLoaded', () => init());

// setup an event listener so we can initialise as required
document.addEventListener('holograph-subscription-init', () => init());
